<template>
  <div>
    <b-card-header class="pb-50">
      <h5 class="mb-1">Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <dynamic-select
            id="customerName"
            label="Cliente"
            placeholder="Digite o nome do cliente"
            v-model="customer"
            :options="customers"
            :loading="loading.customers"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="6" class="mb-1">
          <b-form-group label="Parceiro de negócio (PN)" label-for="searchConsultant">
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="consultant_list => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">Sem resultados</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Período" label-for="searchRangeDate">
            <flat-pickr
              id="searchRangeDate"
              v-model="rangeDate"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Empresa" label-for="searchPartnerCompany">
            <v-select
              id="searchPartnerCompany"
              multiple
              v-model="partnerCompany"
              :reduce="partner_company_list => partner_company_list.id"
              :options="partnerCompanies"
              :loading="loading.partnerCompanies"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">Sem resultados</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Produto" label-for="searchProduct">
            <v-select
              id="searchProduct"
              multiple
              v-model="product"
              :reduce="(product_list) => product_list.id"
              :options="products"
              :loading="loading.products"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">Sem resultados</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Solução financeira"
            label-for="searchFinancialSolution"
          >
            <all-select
              id="searchFinancialSolution"
              v-model="financialSolution"
              :options="financialSolutions"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.financialSolutions"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Conciliador do pagamento" label-for="searchConciliator">
            <v-select
              id="searchConciliator"
              multiple
              v-model="paymentConciliator"
              :reduce="conciliator_list => conciliator_list.id"
              :options="paymentConciliators"
              :loading="loading.paymentConciliators"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">Sem resultados</template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 text-right">
          <b-button variant="outline-secondary" @click.prevent="clearSearch" :disabled="loading.search" v-text="'Limpar'" />
          &nbsp;
          <b-button variant="outline-primary" @click.prevent="search(1)" :disabled="loading.search">
            <b-spinner v-if="loading.search" small />
            <feather-icon v-else icon="SearchIcon" class="mr-50" />
            <span>{{ spinnerActive ? "Aguarde..." : "Pesquisar" }}</span>
          </b-button>                    
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BButton, BSpinner, BPopover, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import DynamicSelect from '@/modules/shared/components/DynamicSelect'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";
import AllSelect from "@/modules/shared/components/AllSelect";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    flatPickr,
    AllSelect
  },
  props: {
    search: Function,
    spinnerActive: Boolean
  },
  data() {
    return {
      loading: {
        partnerCompanies: false,
        products: false,
        customers: false,
        consultantList: false,
        structures: false,
        franchises: false,
        creditTypes: false,
        conciliationOptions: false,
        paymentConciliators: false,
        financialSolutions: false,
        search: false
      },
      flatPickrConfig: {
        altFormat: 'j M Y',
        altInput: true,
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: Portuguese,
        minDate: '',
        maxDate: ''
      },
      customer: undefined,
      partnerCompany: undefined,
      product: undefined,
      consultant: undefined,
      structureOption: undefined,
      franchise: undefined,
      creditType: undefined,
      rangeDate: undefined,
      paymentConciliator: undefined,
      financialSolution: undefined,
      clearSelect: null,
      maxMonths: 12,
      dir: 'ltr'
    }
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear };
  },
  computed: {
    ...mapGetters({
      partnerCompanies: sharedTypes.PARTNER_COMPANIES,
      products: sharedTypes.PRODUCTS,
      customers: sharedTypes.CUSTOMERS,
      structures: sharedTypes.STRUCTURE_TYPES,
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      creditTypes: sharedTypes.CREDIT_TYPES,
      paymentConciliators: types.PAYMENT_CONCILIATORS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
      financialSolutions: types.FINANCIAL_SOLUTIONS
    }),
    isStructureDisabled() {
      return (this.consultant && this.consultant.length > 0) || 
        (this.franchise && this.franchise.length > 0)
    },
    isPn() {
      return this.user?.user_role_id != APP_BACKOFFICE_ROLE_ID
        && this.user?.user_role_id != APP_FINANCIAL_ROLE_ID
        && this.user?.user_role_id != APP_ADMIN_ROLE_ID
        && this.user?.user_role_id != APP_CUSTOMER_ROLE_ID
    },
  },
  mounted() {
    this.loadInitialData()
  },
  methods: {
    ...mapActions({
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      getProducts: sharedTypes.GET_PRODUCTS,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getCreditTypes: sharedTypes.GET_CREDIT_TYPES,
      getPaymentConciliators: types.GET_PAYMENT_CONCILIATORS,
      getFinancialSolutions: types.GET_FINANCIAL_SOLUTIONS
    }),
    loadInitialData() {
      this.loading.partnerCompanies = true
      this.getPartnerCompanies()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as empresas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.partnerCompanies = false
        })
      this.loading.products = true
      this.getProducts()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os produtos para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.products = false
        })
      this.loading.franchises = true
      this.getFranchises()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.franchises = false
        })
      this.loading.structures = true
      this.getStructures()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.structures = false
        })

      this.loading.consultantList = true
      this.getConsultants()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultantList = false
        })
      this.loading.creditTypes = true
      this.getCreditTypes()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as naturezas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.creditTypes = false
        })
      this.loading.paymentConciliators = true
      this.getPaymentConciliators()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os conciliadores de pagamento para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.paymentConciliators = false
        })
        this.loading.financialSolutions = true;
        this.getFinancialSolutions()
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as soluções financeiras para seleção. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.financialSolutions = false;
          });
    },
    findCustomers(keyword) {
      this.loading.customers = true
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
        .finally(() => {
          this.loading.customers = false
        });

    },
    clearSearch() {
      this.customer = undefined
      this.partnerCompany = undefined
      this.product = undefined
      this.consultant = undefined
      this.rangeDate = undefined
      this.paymentConciliator = undefined
      this.financialSolutions = undefined
      this.clearSelect += 1
    },
    setStructureOption() {
      if ((this.consultant && this.consultant.length > 0)
        || (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
$themeColor: #f26237;
@import '~vue-slider-component/lib/theme/default.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-group {
  margin-bottom: 0;
}
</style>
